import LOG from '@lib/logger';
import { dataURLtoFile, isBase64 } from '@lib/utils';
import { getUuid, submitToS3 } from '@lib/utils/aws';
import axios, { AxiosError, AxiosInstance } from 'axios';

export interface IEntity {
  id?: string;

  [key: string]: any;
}

export interface IApiResponse<T extends IEntity> {
  count?: number;
  page?: number;
  data?: T | T[];
}

export interface IApiOptions {
  page?: string | number;
  limit?: string | number;
  sort?: string;
  order?: string;
  joins?: string;
  filters?: string;
}

export class ApiOptions implements IApiOptions {
  page?: string | number;
  limit?: string | number;
  sort?: string;
  order?: string;
  joins?: string;
  filters?: string;

  constructor(options?: IApiOptions) {
    this.page = options?.page || 1;
    this.limit = options?.limit || 10;
    this.sort = options?.sort;
    this.order = options?.order;
    this.joins = options?.joins;
    this.filters = options?.filters;
    LOG.debug({ msg: 'ApiOptions', options: this });
  }
}

class EntityManager<T extends IEntity> {
  protected axiosInstance: AxiosInstance;
  protected endpoint: string;
  protected baseURL: string;
  protected BUCKET_DESTINATION: string;
  protected REGION: string;
  protected POOL_ID: string;

  constructor(baseURL: string, endpoint: string, options?: { bucketName: string; region: string; poolId: string }) {
    this.axiosInstance = axios.create({ baseURL });
    this.endpoint = endpoint;
    this.baseURL = baseURL;
    this.BUCKET_DESTINATION = options?.bucketName || process.env.AWS_BUCKET_MEDIA || process.env.NEXT_PUBLIC_AWS_BUCKET_MEDIA || '';
    this.REGION = options?.region || process.env.NEXT_AWS_REGION || process.env.NEXT_PUBLIC_AWS_REGION || 'us-east-1';
    this.POOL_ID = options?.poolId || process.env.AWS_POOL_ID || process.env.NEXT_PUBLIC_AWS_POOL_ID || '';
  }

  private handleError(error: AxiosError): void {
    if (error.response) {
      LOG.error({ msg: 'EntityManager:Response error:', error: error.response.data });
    } else if (error.request) {
      LOG.error({ msg: 'EntityManager:No response received:', error: error.request });
    } else {
      LOG.error({ msg: 'EntityManager:Error setting up request:', error: error.message });
    }
  }

  private handleImage = async (entity: Record<string, unknown>, imagesFields: string[] = []) => {
    const saveImage = async (img: { url: string }, idx: number) => {
      let url;
      if (img) {
        const uploadResponse = await this.uploadImage(img, {}, idx);
        LOG.debug({ msg: 'EntityManager:save:uploadResponse', uploadResponse });
        if (uploadResponse?.url) {
          url = uploadResponse.url || uploadResponse.host.concat(uploadResponse.path);
        }
      }
      return url;
    };
    for (const field of imagesFields) {
      const idx = imagesFields.indexOf(field);
      const img = entity[imagesFields[idx]] as { url: string } | Array<{ url: string }> | undefined;
      LOG.debug({ msg: 'EntityManager:save:images', field, idx, img, entity });
      let url;
      if (Array.isArray(img)) {
        let urls = [];
        for (const i of img) {
          url = await saveImage(i, idx);
          urls.push(url);
        }
        entity[imagesFields[idx]] = urls;
      } else if (img) {
        url = await saveImage(img, idx);
        entity[imagesFields[idx]] = url;
      }
    }
  };

  protected async uploadImage(img: { url: string }, data: Record<string, unknown>, idx: number) {
    if (!img?.url) {
      LOG.warn({ msg: 'No image provided' });
      return;
    }
    if (!isBase64(img?.url)) {
      LOG.warn({ msg: 'Invalid image provided' });
      return { url: img.url };
    }
    if (!this.BUCKET_DESTINATION) {
      LOG.error({ msg: 'No bucket destination provided' });
      return;
    }
    let uploadResponse: any = null;
    try {
      const file = dataURLtoFile(img?.url, `${data?.sku || getUuid()}-${idx}`);
      if (file) uploadResponse = await submitToS3({ file }, this.BUCKET_DESTINATION, { region: this.REGION, poolId: this.POOL_ID });
      LOG.debug({ msg: 'EntityManager:getAll', baseUrl: this.baseURL, endpoint: this.endpoint, file, uploadResponse });
    } catch (error) {
      LOG.error({ msg: 'Error uploading image to S3', error });
    }
    return uploadResponse;
  }

  async save(entity: Record<string, unknown>, imagesFields: string[] = []): Promise<{ error: string | null; data: T | T[] | null }> {
    let response;
    let error: string | null = null;
    await this.handleImage(entity, imagesFields);
    response = entity.id ? await this.update(entity.id as string, entity as Partial<T>) : await this.create(entity as T);
    if (!response?.data) error = 'Error saving entity';
    return { error, data: response?.data || null };
  }

  async getAll(options?: Record<string, string>): Promise<IApiResponse<T> | null> {
    try {
      const response = await this.axiosInstance.get<IApiResponse<T>>(this.endpoint, {
        params: new ApiOptions(options)
      });
      LOG.debug({ msg: 'EntityManager:getAll', baseUrl: this.baseURL, endpoint: this.endpoint, request: response.config });
      return response.data;
    } catch (error) {
      this.handleError(error as AxiosError);
      return null;
    }
  }

  async getById(id: string, options?: Record<string, string>): Promise<IApiResponse<T> | null> {
    try {
      if (!id) return null;
      const response = await this.axiosInstance.get<IApiResponse<T>>(`${this.endpoint}/${id}`, {
        params: new ApiOptions(options)
      });
      LOG.debug({ msg: 'EntityManager:getById', baseUrl: this.baseURL, endpoint: this.endpoint, request: response.config });
      return response.data;
    } catch (error) {
      this.handleError(error as AxiosError);
      return null;
    }
  }

  async create(entity: T): Promise<IApiResponse<T> | null> {
    try {
      delete entity?.id;
      const response = await this.axiosInstance.post<IApiResponse<T>>(this.endpoint, entity);
      LOG.debug({ msg: 'EntityManager:create', baseUrl: this.baseURL, endpoint: this.endpoint, request: response.config, postData: entity });
      return response.data;
    } catch (error) {
      this.handleError(error as AxiosError);
      return null;
    }
  }

  async update(id: string, entity: Partial<T>): Promise<IApiResponse<T> | null> {
    try {
      if (!id) return null;
      delete entity?.id;
      const response = await this.axiosInstance.put<IApiResponse<T>>(`${this.endpoint}/${id}`, entity);
      LOG.debug({ msg: 'EntityManager:update', baseUrl: this.baseURL, endpoint: this.endpoint, request: response.config, id, postData: entity });
      return response.data;
    } catch (error) {
      this.handleError(error as AxiosError);
      return null;
    }
  }

  async delete(id: string): Promise<boolean> {
    try {
      if (!id) return false;
      const response = await this.axiosInstance.delete(`${this.endpoint}/${id}`);
      LOG.debug({ msg: 'EntityManager:delete', baseUrl: this.baseURL, endpoint: this.endpoint, request: response.config });
      return true;
    } catch (error) {
      this.handleError(error as AxiosError);
      return false;
    }
  }
}

export default EntityManager;
