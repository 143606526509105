import Option from '@lib/devias/components/core/option';
import { FormControl, FormHelperText, InputLabel, Select } from '@mui/material';
import React, { FC } from 'react';
import { Controller } from 'react-hook-form';

import InputFieldTip from '@/bundles/core/components/client/input-tip';
import { InputFieldProps } from '@/bundles/core/types';

export type InputSelectProps = {} & InputFieldProps;

const InputSelect: FC<InputSelectProps> = (props) => {
  return (
    <Controller
      name={props.name}
      control={props.control}
      render={({ field }) => {
        const hasError = Boolean(props.errors?.[field.name]);
        const error = (props.errors?.[field.name] as Record<string, string>)?.message as string;
        return (
          <FormControl error={hasError}>
            <InputLabel sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1 }}>
              {props.label || field.name}
              {props.tips?.map((tip, idx: number) => <InputFieldTip key={idx} title={tip.title} Icon={tip.Icon} />)}
            </InputLabel>
            <Select {...field}>
              <Option value="">Seleccionar</Option>
              {props.options?.map((option, idx) => (
                <Option key={idx} value={option.value}>
                  {option.label}
                </Option>
              ))}
            </Select>
            {hasError ? <FormHelperText>{error}</FormHelperText> : <FormHelperText></FormHelperText>}
          </FormControl>
        );
      }}
    />
  );
};

export default InputSelect;
