import { IconButton } from '@mui/material';
import React, { FC, ReactNode } from 'react';

type ActionFormatterType = {
  index?: string;
  row?: any;
  onClick?: (row: any) => void;
  href?: string;
  children?: ReactNode;
  Icon?: any;
};
const ActionFormatter: FC<ActionFormatterType> = ({ onClick, href, row, Icon }) => {
  return (
    <IconButton onClick={onClick}>
      <Icon />
    </IconButton>
  );
};
export default ActionFormatter;
