import { createContext } from 'react';

const init = {
  setLoading: (arg: boolean) => {},
  isLoading: false,
};

const LoaderContext = createContext(init);

export const Provider = LoaderContext.Provider;

export const Consumer = LoaderContext.Consumer;

export default LoaderContext;
