import Option from '@lib/devias/components/core/option';
import LOG from '@lib/logger';
import FilterListIcon from '@mui/icons-material/FilterList';
import FilterListOffIcon from '@mui/icons-material/FilterListOff';
import { Button, Select, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { FC } from 'react';
import { Controller, useForm } from 'react-hook-form';

export interface IFilter {
  type: string;
  name?: string;
  filterKey: string;
  filters: { label: string; value: string }[];
}

export type TableFiltersType = {
  filters: IFilter[];
  applyFilter: (values: unknown) => void;
  defaultValues: Record<string, string>;
  onClear?: () => void;
  onSearch?: (searchKey: string, filter: IFilter) => void;
};

export type SelectDropDownType = {
  items: { label: string; value: string }[];
  onChange?: (value?: string) => void;
  field: object;
};

const SelectDropdown: FC<SelectDropDownType> = ({ field, items }) => (
  <Select {...field} sx={{ maxWidth: '100%', width: '120px' }}>
    <Option value={''}>Todos</Option>
    {items?.map((item, index) => (
      <Option key={index} value={item.value}>
        {item.label}
      </Option>
    ))}
  </Select>
);

const TableFilters: FC<TableFiltersType> = ({ filters, onClear, applyFilter, defaultValues }) => {
  const { reset, control, handleSubmit, ...form } = useForm({ values: defaultValues });

  const onSubmit = (data: any) => {
    LOG.debug({ msg: 'TableFilters:onSubmit', data });
    applyFilter(data);
  };

  const clearFilters = () => {
    reset();
    applyFilter({});
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2, p: 3, pt: 0 }}>
        {filters?.map((filter, index) => (
          <Controller
            key={index}
            control={control}
            name={filter.filterKey as never}
            render={({ field }) => {
              return (
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                  <Box>
                    <Typography fontSize={14}>Filtrar {filter.name && `por: ${filter.name}`}</Typography>
                  </Box>
                  <SelectDropdown field={field} items={filter.filters} />
                </Box>
              );
            }}
          ></Controller>
        ))}
        <Box sx={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'center' }}>
          <Button color="secondary" variant="text" sx={{ display: 'flex', gap: 1 }} onClick={clearFilters}>
            <Typography fontSize="small">Limpiar Filtro</Typography>
            <FilterListOffIcon fontSize="small" />
          </Button>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'center' }}>
          <Button type="button" color="primary" variant="text" onClick={async () => handleSubmit(onSubmit)()} sx={{ display: 'flex', gap: 1 }}>
            <Typography fontSize="small">Aplicar Filtro</Typography>
            <FilterListIcon fontSize={'small'} />
          </Button>
        </Box>
      </Box>
    </form>
  );
};
export default TableFilters;
