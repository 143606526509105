export interface IBaseEntity {
  id?: string;
  createdAt?: string;
  updatedAt?: string;
  toString?: () => string;
  toFormData?: () => Record<string, unknown>;
}

class BaseEntity implements IBaseEntity {
  id: string = '';
  createdAt: string = new Date().toISOString();
  updatedAt: string = new Date().toISOString();

  constructor(data: Record<string, unknown> & IBaseEntity) {
    this.id = data?.id || this.id;
    this.createdAt = data?.createdAt || this.createdAt;
    this.updatedAt = data?.updatedAt || this.updatedAt;
  }

  toString() {
    return JSON.stringify(this);
  }

  toFormData() {
    let data: { id?: string } = Object.assign({}, this);
    delete data.id;
    return data;
  }
}

export default BaseEntity;
