import LOG from '@lib/logger';

export const serialize = async (value: string | object) => {
  let obj = {};
  try {
    obj = typeof value === 'string' ? JSON.parse(value) : JSON.parse(JSON.stringify(value));
  } catch (error) {
    console.warn((error as Error).message);
  }
  return obj;
};

export const unserialize = (value: string) => JSON.stringify(serialize(value), null, 2);

export function fileToBase64(file: Blob): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      resolve(reader.result as string);
    };
    reader.onerror = () => {
      reject(new Error('Error converting file to base64'));
    };
  });
}

export const isObject = (value: any) => value !== null && typeof value === 'object';

export const ARS = (amount: any) => (amount && typeof amount === 'number' ? Intl.NumberFormat('es-AR', { style: 'currency', currency: 'ARS' }).format(amount) : parseFloat('0').toFixed(2));

export const dataURLtoFile = (dataUrl: string, filename: string) => {
  try {
    if (!dataUrl) return;

    const arr = dataUrl.split(',');
    let mime = arr[0]?.match(/:(.*?);/)?.[1];
    let bstr = atob(arr[1]);
    let n = bstr.length;
    let u8arr = new Uint8Array(n);
    let ext = mime?.split('/')?.[1];

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], `${filename}.${ext}`, { type: mime });
  } catch (err) {
    console.warn(dataUrl, (err as Error).message, (err as Error).stack);
  }
};

export const isBase64 = (source: string) => {
  try {
    const arr = source?.split(',');
    return Array.isArray(arr) && arr.length > 1 && arr[0].includes('base64');
  } catch (err) {
    console.warn(source, (err as Error).message, (err as Error).stack);
  }
  return false;
};

type AnyObject = { [key: string]: any };

export function arrayOfUniqueKey(array: AnyObject[], key: string = 'name'): AnyObject[] {
  if (!Array.isArray(array)) return [];
  if (array.length === 0) return [];
  const uniqueObjects: { [key: string]: AnyObject } = {};
  array.forEach((item) => {
    if (!uniqueObjects[item[key]]) {
      uniqueObjects[item[key]] = item;
    }
  });
  return Object.values(uniqueObjects);
}

export function objectToUrlSearchParams(obj: Record<string, any>): string {
  const params = new URLSearchParams();

  Object.keys(obj).forEach((key) => {
    const value = obj[key];
    if (Array.isArray(value)) {
      value.forEach((val) => params.append(key, val));
    } else {
      params.append(key, value);
    }
  });

  return params.toString();
}

export const debounce = <T extends (...args: any[]) => void>(func: T, wait: number): ((...args: Parameters<T>) => void) => {
  let timeout: ReturnType<typeof setTimeout>;
  return (...args: Parameters<T>) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => func(...args), wait);
  };
};

export const objEmpty = (obj: Record<string, any>) => Object.keys(obj).length === 0 && obj.constructor === Object;

export const arrayEmpty = (arr?: any[]) => Array.isArray(arr) && arr.length === 0;

export const getValueFromPath = (path: string, obj: Record<string, never>): unknown => {
  return path.split('.').reduce((acc, part) => acc && acc[part], obj);
};

export const fetcher = async (resource: string, init: RequestInit) =>
  new Promise(async (resolve, reject) => {
    let res: Response | null = null;
    try {
      res = await fetch(resource, init);
      if (!res.ok) {
        LOG.warn({ msg: 'fetcher', status: res.status, statusText: res.statusText });
        reject({ response: res.statusText });
      }
      const data = await res.json();
      resolve(data);
    } catch (err) {
      LOG.error({ msg: 'fetcher', err });
      reject({ response: res?.statusText, error: err });
    }
  });

export const buildFormSelectOptions = (items: unknown) => {
  if (!Array.isArray(items)) return [];
  return (items as any[]).map((item) => ({ label: item.name, value: item.id, data: item }));
};


export function updateQueryStringParameter(url: string, key: string, value: string): string {
  const parsedUrl = new URL(url);
  const params = new URLSearchParams(parsedUrl.search);
  params.set(key, value);
  parsedUrl.search = params.toString();
  return decodeUrl(parsedUrl.toString());
}

export function decodeUrl(url: string): string {
  try {
    return decodeURIComponent(url);
  } catch (e) {
    console.error('Error decoding URL:', e);
    return url;
  }
}
