import LOG from '@lib/logger';
import { ISearchOptions } from '@lib/tables/table';
import { useEffect, useState } from 'react';

export type ItemListProps = {
  selectable?: boolean;
  items: unknown[];
  page?: number;
  count?: number;
  columns: unknown;
  filters?: unknown;
  selectedItems?: Set<string | number>;
  applyFilters?: (filters: { [key: string]: string }) => void;
  defaultFilterValues?: Record<string, string>;
  defaultSearchFilterValues?: Record<string, string>;
  searchOptions?: ISearchOptions;
  onSelect?: (ev: unknown, row: unknown) => void;
  onDeselect?: (ev: unknown, row: unknown) => void;
  onChangePage?: (pageNumber: number) => void;
};

const useItemList = (props: ItemListProps) => {
  const [page, setPage] = useState<number | string>(1);
  const [limit, setLimit] = useState<number | string>(50);
  const [count, setCount] = useState<number | string>(0);
  const [items, setItems] = useState<any[]>([]);

  const goToPage = (pageNumber: number) => {
    LOG.debug({ msg: 'goToPage', pageNumber });
    props.onChangePage?.(pageNumber);
    setPage(pageNumber);
  };

  const onLimitChange = (limit: number) => {
    LOG.debug({ msg: 'onLimitChange', limit });
    setLimit(limit);
  };

  const onSearch = (search: string) => {
    LOG.debug({ msg: 'Searching', search });
  };

  useEffect(() => {
    if (props.items) setItems(props.items);
    if (props.page) setPage(props.page);
    if (props.count) setCount(props.count);
  }, [props.items, props.page, props.count]);

  return { page, limit, count, items, onSearch, onLimitChange, goToPage };
};

export default useItemList;
