import { CognitoIdentityClient } from '@aws-sdk/client-cognito-identity';
import { S3 } from '@aws-sdk/client-s3';
import { fromCognitoIdentityPool } from '@aws-sdk/credential-provider-cognito-identity';
import { Progress, Upload } from '@aws-sdk/lib-storage';
import { XhrHttpHandler } from '@aws-sdk/xhr-http-handler';
import { v4 as uuidv4 } from 'uuid';

export const getS3Client = async (region: string, poolId: string) => {
  // console.log('getS3Client -> poolId', poolId);
  // console.log('getS3Client -> region', region);
  const config = {
    region,
    requestHandler: new XhrHttpHandler({}),
    credentials: fromCognitoIdentityPool({
      client: new CognitoIdentityClient({ region }),
      identityPoolId: poolId
    })
  };
  return new S3(config);
};

export const submitToS3 = async (
  values: { file: File },
  bucketName: string,
  clientConfig: { region: string; poolId: string },
  destinationPath = 'uploads/images/products'
): Promise<{ path: string; host: string; url: string }> => {
  return new Promise(async (resolve, reject) => {
    try {
      if (!values?.file) throw new Error('No file to upload!!');
      const ext = values?.file?.type?.split('/')?.at(-1) || 'image';
      const type = values?.file?.type?.split('/')?.at(0) || 'image';
      const name = values?.file?.name || uuidv4();
      const media = {
        path: `${destinationPath}/${name}`,
        mimetype: values?.file?.type,
        type: type
      };
      // console.log('submitToS3 -> media', media);
      const s3c = await getS3Client(clientConfig.region, clientConfig.poolId);
      const resp = await upload(s3c, media.path, bucketName, values.file);
      if (!resp?.host) throw new Error(`media upload failure!`);
      let host = `https://s3.amazonaws.com/`.concat(bucketName);
      return resolve({ ...media, ...resp, host, url: host?.concat('/')?.concat(media.path) });
    } catch (err) {
      // console.warn((err as Error).message);
      reject(err);
    }
  });
};

export const onProgress = (prog: Progress, callback: (ev: { percent: number }) => void) => {
  if (!prog?.loaded || !prog?.total) return;
  let percent = (prog.loaded * 100) / prog.total;
  callback({ ...prog, percent });
};

export const upload = async (client: S3, Key: string, Bucket: string, file: File, onUploadProgress?: (process: Progress) => void) => {
  try {
    if (!client) throw new Error('S3 client error!');
    // console.log('upload -> s3', Bucket, Key, file.name);
    const s3upload = new Upload({
      client: client,
      params: {
        Bucket: Bucket,
        Key: Key,
        Body: file,
        ContentType: file.type
        // ACL: "public-read",
      }
    });
    onUploadProgress && s3upload.on('httpUploadProgress', onUploadProgress);
    const resp: any = await s3upload.done();
    if (!resp?.Location) throw new Error('upload issues !');
    const url = new URL(resp.Location);
    const output = { file, host: url.origin, path: url.pathname };
    // console.log('upload -> output', output);
    return output;
  } catch (error) {
    console.warn((error as Error).message);
  }
};

export const getUuid = () => uuidv4();
