import { FC, ReactNode, useEffect, useState } from 'react';
import { toast } from 'sonner';

import { Provider } from '../context';

export type FlashProviderProps = { children: ReactNode };

const FlashProvider: FC<FlashProviderProps> = ({ children }) => {
  const [flash, setFlash] = useState<{ message?: string; type?: string; description?: string }>();

  const setFlashMessage = (msg: string, type: string = 'info', description?: string) => {
    setFlash({ message: msg, type, description });
  };

  useEffect(() => {
    if (flash?.message) {
      if (flash.type === 'error') toast.error(flash.message, { description: flash.description });
      if (flash.type === 'success') toast.success(flash.message, { description: flash.description });
      if (flash.type === 'warning') toast.warning(flash.message, { description: flash.description });
      if (flash.type === 'info') toast.info(flash.message, { description: flash.description });
      if (flash.type === 'default') toast(flash.message, { description: flash.description });
    }
  }, [flash]);

  return <Provider value={{ flash, toast: setFlashMessage }}>{children}</Provider>;
};

export default FlashProvider;
