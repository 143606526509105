// @ts-nocheck
import { ColumnDef, DataTable } from '@/lib/devias/components/core/data-table';
import LOG from '@lib/logger';
import TableFilters from '@lib/tables/table/filters';
import { Divider, InputAdornment, OutlinedInput, Typography } from '@mui/material';
import Stack from '@mui/material/Stack';
import TablePagination from '@mui/material/TablePagination';
import { Box } from '@mui/system';
import { MagnifyingGlass as MagnifyingGlassIcon } from '@phosphor-icons/react/dist/ssr/MagnifyingGlass';
import React, { FC, useEffect, useState } from 'react';

export interface ISearchOptions {
  title: string;
  onChange: (val?: string, filter?: { type: string; filterKey: string; filters: { label: string; value: string }[] }) => void;
}

export interface AppTableProps<T> {
  className?: string;
  count: number;
  page: number;
  selectable?: boolean;
  selected?: Set<string | number>;
  limitChange?: (limit: number) => void;
  onDeselectAll?: (event: React.ChangeEvent) => void;
  onDeselectOne?: (event: React.ChangeEvent, row: TRowModel) => void;
  onSelectAll?: (event: React.ChangeEvent) => void;
  onSelectOne?: (event: React.ChangeEvent, row: TRowModel) => void;
  onPageChange?: (event: React.ChangeEvent, page: number) => void;
  onRowsPerPageChange?: (event: React.ChangeEvent, rowsPerPage: number) => void;
  searchOptions?: ISearchOptions;
  columns: ColumnDef<T>[];
  items: T[];
  filters?: IFilter[];
  applyFilters?: (filters: { [key: string]: string }) => void;
  clearFilters?: () => void;
  defaultFilterValues?: Record<string, string>;
  defaultSearchFilterValues?: Record<string, string>;
}

const SearchBar: FC<{ defaultSearchFilterValues?: Record<string, string>; placeholder: string; onChange: (value?: string) => void }> = ({ placeholder, onChange }) => (
  <Stack>
    <OutlinedInput
      placeholder={placeholder}
      onChange={(e) => (onChange ? onChange(e.target.value) : () => {
      })}
      startAdornment={
        <InputAdornment position="start">
          <MagnifyingGlassIcon fontSize="var(--icon-fontSize-md)" />
        </InputAdornment>
      }
      sx={{ flex: '1 1 auto', mx: 3 }}
    />
  </Stack>
);

const AppTable: FC<AppTableProps<unknown>> = ({
                                                defaultFilterValues,
                                                defaultSearchFilterValues,
                                                applyFilters,
                                                clearFilters,
                                                searchOptions,
                                                columns,
                                                items,
                                                filters,
                                                count = 0,
                                                page = 1,
                                                onPageChange,
                                                limitChange,
                                                selectable,
                                                ...props
                                              }) => {
  const [rowsPerPage, setRowsPerPage] = useState(50);

  useEffect(() => {
    if (props.page) LOG.debug({ msg: 'AppTable:useEffect:page', page: props.page });
  }, [page]);

  return (
    <Box sx={{}}>
      <Box>
        <Stack direction="column" spacing={2} sx={{}}>
          {searchOptions && <SearchBar defaultSearchFilterValues={defaultSearchFilterValues} placeholder={searchOptions?.title} onChange={searchOptions?.onChange} />}
          <Stack direction={'row'} spacing={3}>
            {filters && applyFilters && <TableFilters defaultValues={defaultFilterValues} applyFilter={applyFilters} onClear={clearFilters} filters={filters} />}
          </Stack>
        </Stack>
        <Divider />
        <Box sx={{ overflowX: 'auto' }}>
          <DataTable<any> columns={columns} rows={items} selected={props.selected} selectable={selectable} onSelectOne={props.onSelectOne} onDeselectOne={props.onDeselectOne} />
          {!items.length ? (
            <Box sx={{ p: 3 }}>
              <Typography color="text.secondary" sx={{ textAlign: 'center' }} variant="body2">
                Sin resultados
              </Typography>
            </Box>
          ) : null}
        </Box>
        <Box>
          <TablePagination
            labelRowsPerPage="Filas por página"
            component="div"
            count={count}
            onPageChange={(ev, p) => {
              LOG.debug({ msg: 'TablePagination:onPageChange', page: p });
              onPageChange?.(ev, p);
            }}
            onRowsPerPageChange={(ev, rowsPerPage) => {
              limitChange?.(rowsPerPage?.props?.value);
              setRowsPerPage(rowsPerPage?.props?.value);
            }}
            page={page}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[5, 50, 100, 500, 1000]}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default AppTable;
