import { getValueFromPath } from '@lib/utils';
import { FormControl, FormHelperText, InputLabel, OutlinedInput } from '@mui/material';
import React, { FC, useMemo } from 'react';
import { Controller } from 'react-hook-form';

import InputFieldTip from '@/bundles/core/components/client/input-tip';
import { InputFieldProps } from '@/bundles/core/types';

const InputField: FC<InputFieldProps> = (props) => {
  const name = useMemo(() => {
    if (props.arrayIndex !== undefined) return `${props.arrayIndex}.${props.name}`;
    return props.name;
  }, [props.name, props.arrayIndex]);

  return (
    <Controller
      name={name}
      control={props.control}
      disabled={props.disabled}
      rules={props.rules}
      render={({ field }) => {
        const errors = getValueFromPath(field.name, props.errors as never) as { message: string };
        const hasError = Boolean(errors?.message);
        const error = hasError ? (errors?.message as string) : null;

        // LOG.debug({ msg: 'InputField', field, hasError, error, rules: props.rules });
        return (
          <FormControl error={hasError} fullWidth>
            <InputLabel sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1 }}>
              {props.label || field.name}
              {props.tips?.map((tip, idx: number) => <InputFieldTip key={idx} title={tip.title} Icon={tip.Icon} />)}
            </InputLabel>
            {props.type === 'number' ? (
              <OutlinedInput {...field} type="number" multiline={props.multiline} onChange={(event) => field.onChange(+event.target.value)} required={props.required} />
            ) : (
              <OutlinedInput {...field} multiline={props.multiline} type={props.type} required={props.required} autoComplete={props.autoComplete} />
            )}
            {hasError ? <FormHelperText>{error}</FormHelperText> : <FormHelperText></FormHelperText>}
          </FormControl>
        );
      }}
    />
  );
};

export default InputField;
