import { Tooltip } from '@mui/material';
import React, { FC } from 'react';

export type InputFieldTipProps = {
  title: string;
  Icon: React.ComponentType<{ color?: string; fontSize?: string; weight?: string }>;
};

const InputFieldTip: FC<InputFieldTipProps> = ({ title, Icon }) => {
  return (
    <Tooltip title={title}>
      <Icon color="var(--mui-palette-text-secondary)" fontSize="var(--icon-fontSize-md)" weight="fill" />
    </Tooltip>
  );
};

export default InputFieldTip;
