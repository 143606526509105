import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import { FC, ReactNode } from 'react';

type TableFormatterType = {
  row: any;
  index: string;
  children?: ReactNode;
};

const TableFormatter: FC<TableFormatterType> = ({ row, index }) => {
  return (
    <Box>
      <Typography sx={{ whiteSpace: 'nowrap' }} variant="inherit">
        {row?.[index]}
      </Typography>
    </Box>
  );
};
export default TableFormatter;
