import { Stack } from '@mui/system';
import React, { FC } from 'react';

import { IFormField } from '@/bundles/core/types';

export type RowFieldProps = {
  field: IFormField;
  control: never;
  form: never;
  arrayIndex?: string | number;
};

const RowField: FC<RowFieldProps> = (props) => {
  if (Array.isArray(props.field)) {
    return (
      <Stack direction="row" spacing={2}>
        {props.field.map((f, idx) => {
          return <f.Component key={idx} control={props.control} {...f} arrayIndex={props.arrayIndex} form={props.form} />;
        })}
      </Stack>
    );
  }
  return <props.field.Component control={props.control} {...props.field} arrayIndex={props.arrayIndex} form={props.form} />;
};

export default RowField;
