import { createContext } from 'react';

const init: { flash?: { message?: string; type?: string; description?: string }; toast: (msg: string, type?: string, description?: string) => void } = {
  flash: {},
  toast: (msg: string, type: string = 'info', description?: string) => {},
};

const FlashContext = createContext(init);

export const Provider = FlashContext.Provider;

export const Consumer = FlashContext.Consumer;

export default FlashContext;
