'use client';

import { ColumnDef } from '@lib/devias/components/core/data-table';
import LOG from '@lib/logger';
import AppTable, { ISearchOptions } from '@lib/tables/table';
import { IFilter } from '@lib/tables/table/filters';
import Box from '@mui/material/Box';
import { FC } from 'react';

import useItemList, { ItemListProps } from './hooks/useItemList';

const ItemList: FC<ItemListProps> = (props) => {
  const pl = useItemList(props);

  return (
    <Box>
      <AppTable
        onPageChange={(_, page) => {
          LOG.debug({ msg: 'onPageChange', page });
          if (Number(page)) pl.goToPage(page);
        }}
        limitChange={pl.onLimitChange}
        selected={props.selectedItems}
        selectable={props.selectable}
        page={parseInt(pl.page as string)}
        count={parseInt(pl.count as string)}
        items={pl.items}
        columns={props.columns as ColumnDef<unknown>[]}
        searchOptions={props.searchOptions as ISearchOptions}
        filters={props.filters as IFilter[]}
        applyFilters={props.applyFilters}
        defaultSearchFilterValues={props.defaultSearchFilterValues}
        defaultFilterValues={props.defaultFilterValues}
        onSelectOne={props.onSelect}
        onDeselectOne={props.onDeselect}
      />
    </Box>
  );
};

export default ItemList;
