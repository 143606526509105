import pino from 'pino';

const LOG_LEVEL = process.env.NEXT_PUBLIC_LOG_LEVEL || 'debug';

const LOG = pino({
  level: LOG_LEVEL,
  browser: {
    serialize: true,
    asObject: true,
  },
});

export default LOG;
